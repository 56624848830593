import { noop, throttle } from 'lodash-es'

export const useScrollBehavior = (options?: {
  preventCondition: CallableFunction
}) => {
  if (import.meta.server) {
    return {
      enableScroll: noop,
      disableScroll: noop,
    }
  }

  const keys = [32, 33, 34, 35, 36, 37, 38, 39, 40]
  const wheelOpt = { passive: false }
  const wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

  const preventDefault = (e: Event) => {
    let shouldPrevent = true
    if (options?.preventCondition) {
      shouldPrevent = options.preventCondition(e)
    }

    shouldPrevent && e.preventDefault()
  }

  const preventDefaultForScrollKeys = (e: KeyboardEvent) => {
    if (keys.includes(e.keyCode)) {
      preventDefault(e)
      return false
    }
  }

  const enableScroll = () => {
    window._preventScroll = false
    window.removeEventListener(wheelEvent, preventDefault)
    window.removeEventListener('DOMMouseScroll', preventDefault, false)
    window.removeEventListener('touchmove', preventDefault)
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false)
  }

  const disableScroll = () => {
    window._preventScroll = true
    window.addEventListener(wheelEvent, preventDefault, wheelOpt)
    window.addEventListener('DOMMouseScroll', preventDefault, false)
    window.addEventListener('touchmove', preventDefault, wheelOpt)
    window.addEventListener('keydown', preventDefaultForScrollKeys, false)
  }

  return {
    enableScroll,
    disableScroll,
  }
}

export const usePasteFromClipboard = () => {
  const { currentTask } = useWorkspaceSharedState()
  const { processAddAttachment } = useUpdateTask()
  const { currentFocusKanbanTask, isFocusKanbanTask, currentHoverSection } =
    useKanbanSharedState()

  const isEditableElement = (element: HTMLElement) => {
    return (
      element.tagName === 'INPUT' ||
      element.tagName === 'TEXTAREA' ||
      element.isContentEditable
    )
  }

  const onPaste = async (event: ClipboardEvent) => {
    if (isEditableElement(document.activeElement as HTMLElement)) {
      return
    }

    const pastedFiles = event.clipboardData?.files
    const pastedText =
      event.clipboardData?.getData('text') ||
      (!pastedFiles?.length ? await navigator.clipboard.readText() : null) || ''

    if (!pastedText && !pastedFiles?.length) {
      return
    }

    const targetTaskId =
      currentTask.value?.id ||
      (isFocusKanbanTask.value ? currentFocusKanbanTask.value?.id : null)
    // If the task is not focused, paste to the hovered section and create a new task
    if (!targetTaskId) {
      if (currentHoverSection.value !== -1 && pastedText) {
        const { emit } = useEventBus(
          `section-listener-${currentHoverSection.value}`
        )

        return emit('paste-clipboard', pastedText)
      }

      return
    }

    const filesArray = pastedFiles ? Array.from(pastedFiles) : null
    await processAddAttachment(filesArray, pastedText, targetTaskId)
  }

  const throttlePaste = throttle(onPaste, 500, {
    leading: true,
    trailing: false,
  })

  onMounted(() => {
    window.addEventListener('paste', throttlePaste)
  })

  onUnmounted(() => {
    window.removeEventListener('paste', throttlePaste)
  })

  return {
    onPaste,
  }
}
